@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

.tree {


    .node-wrapper {
        height: 28px;
        padding: 0 0 0 8px !important;
        display: flex;
        align-items: center;
    }

    .node-content-wrapper {
        margin: 0 0 0 18px;
        padding: 0;
        position: static;
        width: 100%;
    }

    .node-content-wrapper, .tree-children {
        position: relative;
        overflow: visible;
    }

    .node-content-wrapper::before, .tree-children::after {
        content: "";
        position: absolute;
    }

    .node-content-wrapper-active {
        background-color: #E5E8F9;

        .tree__name {
            font-weight: 600;
            color: #34BFA3;
        }
    }

    .node-content-wrapper::before {
        // border-bottom: 1px dashed #CFD1D4;
        // border-left: 1px dashed #CFD1D4;
        // height: 28px;
        // top: -17px;
        // width: 10px;
        // left: -29px;
    }

    .tree-node-level-1 > tree-node-wrapper > .node-wrapper > .node-content-wrapper::before {
        display: none;
    }

    .tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
        width: 25px;
    }

    .tree-children::after {
        border-left: 1px solid #CFD1D4;
        height: 100%;
        top: -15px;
        left: -15px;
        display: none;
    }

    tree-node:last-child > .tree-node > .tree-children::after {
        border-left: none;
    }

    .toggle-children-wrapper {
        border: 1px solid #CFD1D4;
        box-sizing: border-box;
        width: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        padding: 0;
        position: relative;
        top: -1px;
        margin: 0 0 2px 0;

        // &::after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     left: 0;
        //     height: 10px;
        //     width: 14px;
        //     border-top: 1px dashed #CFD1D4;
        // }



        .toggle-children {
            z-index: 1;
            // background-color: #64666E;
            background-image: none;
            // width: 8;
            // height: 2px;
            position: relative;

            &::after {
                content: '+';
                position: absolute;
                width: 100%;
                font-size: 14px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                // transform: translateY(-2px);
                line-height: 18px;
            }
        }

        &-expanded {

            &::before {
                content: '';
                position: absolute;
                top: 100%;
                left: 8px;
                height: 10px;
                width: 10px;
                border-left: 1px dashed #CFD1D4;
            }

            .toggle-children {
                transform: none;

                &::after {
                    content: '-';
                    position: absolute;
                    width: 100%;
                    font-size: 14px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // transform: translateY(-2px);
                    line-height: 18px;
                }
            }
        }
    }

    .tree-node-level-2, .tree-node-level-3 {
        padding: 0 0 0 14px;
    }


    .tree-node-level-1 {
        position: relative;

        &.tree-node-expanded {
            .toggle-children-wrapper {
                position: static;

                &::before {
                    content: '';
                    position: absolute;
                    top: 24px;
                    left: 16px;
                    height: calc(100% - 24px);
                    width: 10px;
                    border-left: 1px dashed #CFD1D4;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 24px;
                    left: 51px;
                    height: calc(100% - 24px);
                    width: 10px;
                    border-left: 1px dashed #CFD1D4;
                }
            }
        }

        &.tree-node-collapsed {
            .toggle-children-wrapper {
                position: static;

                &::before {
                    content: '';
                    position: absolute;
                    top: 24px;
                    left: 16px;
                    height: 4px;
                    width: 10px;
                    border-left: 1px dashed #CFD1D4;
                }
            }
        }

    }

    .tree-node-level-2 {
        position: relative;

        &.tree-node-expanded {
            .toggle-children-wrapper {
                position: static;

                &::before {
                    content: '';
                    position: absolute;
                    top: 24px;
                    left: 30px;
                    height: calc(100% - 45px);
                    width: 10px;
                    border-left: 1px dashed #CFD1D4;
                }
            }
        }

        &.tree-node-collapsed {
            .toggle-children-wrapper {
                position: static;

                &::before {
                    content: '';
                    position: absolute;
                    top: 24px;
                    left: 30px;
                    height: 4px;
                    width: 10px;
                    border-left: 1px dashed #CFD1D4;
                }
            }
        }
    }

    .tree__item {

        &--coordinator {
            .tree__icon {
                &::after {
                    display: none;
                }
            }
        }

    }

    .tree__icon {
        &::before {
            content: '';
            position: absolute;
            border-top: 1px dashed #CFD1D4;
            height: 9px;
            top: 50%;
            // transform: translateY(-50%);
            width: 14px;
            // left: 6px;
            right: calc(100% + 4px);
        }
    }

    .tree-node-level-1.tree-node-expanded {
        .tree__icon {
            position: relative;



            &::after {
                content: '';
                position: absolute;
                border-left: 1px dashed #CFD1D4;
                height: 9px;
                top: 100%;
                width: 10px;
                left: 6px;
                left: 50%;
            }
        }
    }

    .tree-node-level-2.tree-node-collapsed {
        .tree__icon {

            &::after {
                display: none;
            }
        }
    }

    .tree-node-level-2.tree-node-expanded {

        &::after {
            content: '';
            position: absolute;
            border-left: 1px dashed #CFD1D4;
            height: calc(100% - 45px);
            top: 24px;
            width: 10px;
            left: 12px;
            left: 69px;
        }

        .tree__icon {
            position: static;

            &::after {
                content: '';
                position: absolute;
                border-left: 1px dashed #CFD1D4;
                // height: 9px;
                height: calc(100% - 9px);
                top: 100%;
                width: 10px;
                left: 12px;
                left: 50%;
                // display: none;
            }

            // &--coordinator {
            //     &::after {
            //         display: none;
            //     }
            // }
        }
    }


    .tree-node-level-3.tree-node-collapsed {
        .tree__icon {
            &::after {
                display: none;
            }
        }
    }


    .tree-node-level-3.tree-node-leaf {
        .node-content-wrapper {
            margin: 0 0 0 13px !important;
        }

        .tree__icon {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                border-left: none;
                border-top: 1px dashed #CFD1D4;
                height: 9px;
                top: 100%;
                width: 18px;
                left: -12px;
            }
        }
    }

    .tree-node-level-3.tree-node-leaf {
        .tree__icon {
            &::before {
                display: none;
            }
        }
    }

    .node-wrapper {

        &:hover {
            // .tree-node {
                position: relative;
                background-color: var(--treItemHover);
                box-shadow: var(--treItemHoverShadow)
            // }
        }
    }
    tree-node {
        &:hover {
            background-color: var(--treeNodeWrapper);
        }

        &:last-child {
            .toggle-children-wrapper {
                &::before {
                    display: none;
                }
            }
        }

    }

    // .node-content-wrapper:hover {
    //     background: var(--treItemHover);
    //     box-shadow: var(--treItemHoverShadow)
    // }

    // .node-content-wrapper-active {
    //     &::before {
    //         position: absolute;
    //         content: 'йцуйцуйцуйуцйуйуйуйуйуйцу ваыв';
    //         width: 40px;
    //         height: 20px;
    //         top: 0;
    //         left: 0;
    //         background: red;
    //     }
    // }

    // .node-content-wrapper.node-content-wrapper-active:hover {

    // }

    // .node-content-wrapper-active.node-content-wrapper-focused {
    //     box-shadow: none;
    //     background: none !important;
    // }
    .node-content-wrapper,
    .node-content-wrapper-active,
    .node-content-wrapper.node-content-wrapper-active:hover,
    .node-content-wrapper-active.node-content-wrapper-focused {
        background: none !important;
        box-shadow: none;
    }


}

.toggle-children-placeholder {
    margin-left: 5px;
}
