@import "variables";
@import "mixins/media-mixin";
@import "_fonts";


html {
    height: 100%;
}

body {
    text-decoration: none;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    background-color: var(--contentBgColor);
}

body,
form,
div,
p,
a,
img,
h3,
h4,
h5,
h6,
table,
table tr,
table td {
    border: none;
    color: var(--baseFontColor);
    font-family: 'Open sans', sans-serif;
    //font-size: $normalFontSize;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;

}

input,
textarea,
select {
    outline: none;
    font-family: 'Open sans', sans-serif;
    //font-size: 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //font-size: $normalFontSize;
    font-size: 14px;
    border: none;
    filter: none;

    // @include mq(1600px) {
    //     font-size: $inputFontSize15k;
    // }
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    color: var(--baseFontColor) !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--baseFontColor) !important;
    ;
}

input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--baseFontColor) !important;
    ;

}

a {
    outline: none;
    text-decoration: none;
    color: var(--baseFontColor);
}

a:hover {
    text-decoration: none;
}

ul,
li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

button {
    font-family: 'Open sans', sans-serif;
    cursor: pointer;
    outline: none;
}

h1,
h2 {
    font-family: 'Open sans', sans-serif;
    font-size: 20px;
    padding: 0;
    margin: 0;
    color: #64666E;
}

.clear {
    clear: both;
    font-size: 0;
    line-height: 0;
    height: 0;
}
