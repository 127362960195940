
// .report-print {
//     display: none;
// }
@media print {
    .report-print {
        display: block !important;


        // &--a4 {
        //     @page {
        //         size: A4;
        //     }
        // }

        // &--a4-land {
        //     @page {
        //         size: A4 landscape;
        //     }
        // }


    }

    .tabs {
        display: none;
    }
    
    .heading {
        display: none;
    }

    .nav, .footer, .create-report {
        display: none;
    }

    .container,
    .container__main,
    .content,
    .content__wrapper {
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: none !important;
    }

    .placeholder {
        display: none;
    }
    .note {
        display: none !important;
    }
}
