@import './assets//scss/_reset.scss';
@import './assets//scss/_print.scss';
@import './assets//scss/_tree.scss';


html{
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 7px;
    height: 6px;
    background-color: var(--scrollbarBg);
}
::-webkit-scrollbar-track {
    background: var(--scrollbarTrack);
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbarThumb);
    border-radius: 3px;
    width: 3px;
}

#e1ddznt4vu0r134 {
    g {
        path {
            fill: var(--preloaderColor);
        }
    }
}

#e1ddznt4vu0r208 {
    g {
        path {
            fill: var(--preloaderColor);
        }
    }
}

#e1ddznt4vu0r84 {
    g {
        path {
            fill: var(--preloaderColor);
        }
    }
}
