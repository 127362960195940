@font-face {
  font-family: 'Open sans';
  src: url("../fonts/open-sans/OpenSans-Regular.ttf");
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/Poppins-Regular.ttf");
  font-style: normal;
  font-stretch: normal;
}

// @font-face {
//     font-family: 'icomoon';
//     src: url('../fonts/icomoon.woff2') format('woff2'),
//     url('../fonts/icomoon.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
